import audit_2019 from '../../../assets/files/ru/Audit_2019.pdf';

export default {
    seo: {
      title: 'Финансовая отчетность за 2019 год | Банк 131',
      description: 'Финансовая отчетность за 2019 год'
    },
    title: 'Финансовая отчетность за 2019 год',
    content: [
         {
             label: 'Дата размещения 03.03.2020',
             title: 'Годовая бухгалтерская (финансовая) отчетность за 2019 год.zip',
             link: '/legal/ru/ru_publ_03032020.zip',
         },
         {
             title: 'Аудиторское заключение о годовой бухгалтерской (финансовой) отчетности за 2019 год.pdf',
             link: audit_2019,
         },
         {
             label: 'Дата размещения 07.11.2019',
             title: 'Публикуемая отчетность за 9 месяцев 2019 года.zip',
             link: '/legal/ru/ru_9_mes_2019.zip',
         },
         {
             label: 'Дата размещения 09.08.2019',
             title: 'Публикуемая отчетность за первое полугодие 2019 года.zip ',
             link: '/legal/ru/ru_1_polugodie_2019.zip',
         }
     ]
}