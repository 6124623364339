export default {
    seo: {
      title: 'Financial statements for 2019 | Bank 131',
      description: 'Financial statements for 2019'
    },
    title: 'Financial statements for 2019',
    content: [
         {
             label: 'Posting date 03.03.2020',
             title: 'Annual accounting (financial) statements for 2019.zip',
             link: '/legal/en/Annual_accounting_statements_for_2019_en.zip',
         },
         {
             title: 'Auditor\'s report on the annual accounting (financial) statements for 2019.docx',
             link: '/legal/en/Audit_2019_en_Auditor_s_Report_2019.docx',
         },
         {
             label: 'Posting date 07.11.2019',
             title: 'Published reports for 9 months of 2019.zip',
             link: '/legal/en/Explanatory_information_01_10_2019_en.zip',
         },
         {
             label: 'Posting date 09.08.2019',
             title: 'Published reports for first half of 2019.zip',
             link: '/legal/en/First_half_of_2019_en.zip',
         }
     ]
}